<template>
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <h3 class="text-center tx-color-01 mg-b-2">Sign In</h3>
      <p class="text-center tx-color-03 tx-16 mg-b-4">Welcome back!</p>


      <div v-if="usingSSOAuth()">
        <br /><br />
        <button
            @click="ssoLogin"
            class="btn btn-outline-primary btn-block"
            :disabled="$store.getters.loggingInWithSSO"
        >
          <span v-if="!$store.getters.loggingInWithSSO">
            <i class="fa fa-globe"></i>&nbsp;
          </span>
          <span v-if="$store.getters.loggingInWithSSO">
            <i class="fa fa-spinner fa-spin"></i>&nbsp;
          </span>
          {{ getSSOLoginLabel() }}
        </button>

        <br />
        <hr />
        <br />
      </div>


      <form v-on:submit.prevent="() => true">
        <div class="form-group">
          <label>{{ getLoginFormLabel() }}</label>
          <input
              :type="inputType"
              class="form-control"
              placeholder="yourname@yourmail.com"
              v-model="model.email"
              :autocomplete="autocomplete"
              tabindex="1"
          />
          <small v-if="emailError && emailError.length > 0" class="text-danger">{{
              emailError
            }}</small>
        </div>
        <div class="form-group">
          <div class="d-flex justify-content-between mg-b-5">
            <label class="mg-b-0-f">Password</label>
            <router-link
                :to="{
                name: 'Forgot Password',
                params: { userEmail: model.email },
              }"
                class="tx-13"
            >Forgot password?
            </router-link>
          </div>
          <div class="input-group">
            <input
                :type="passwordVisible ? 'text' : 'password'"
                class="form-control"
                placeholder="Enter your password"
                v-model="model.password"
                autocomplete="current-password"
                ref="password"
                tabindex="2"
            />
            <div class="input-group-append">
              <button
                  class="btn btn-outline-secondary"
                  type="button"
                  @mousedown="passwordVisible = true"
                  @mouseleave="passwordVisible = false"
                  @mouseup="passwordVisible = false"
              >
                <eye-icon v-if="!passwordVisible"></eye-icon
                ><eye-off-icon v-else></eye-off-icon>
              </button>
            </div>
          </div>
          <small v-if="passwordError && passwordError.length > 0" class="text-danger">{{
              passwordError
            }}</small>
        </div>
        <button
            class="btn btn-brand-02 btn-block"
            @click="login"
            :disabled="loginAttempts >= passwordLockoutLimit || loggingIn"
            tabindex="3"
        >
          Sign In&nbsp;<span v-if="loggingIn"
        ><i class="fa fa-spinner fa-spin fa-2x"></i
        ></span>
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import { EyeIcon, EyeOffIcon } from "vue-feather-icons";
import FeaturesMixin from "@clients/src/mixins/features.mixin";
import UwFeaturesMixin from "@/mixins/features.mixin";
import AuthService from "@underwriters/src/services/auth.service";
import {USER_TYPE} from "@common/src/constants";

export default {
  name: "login",
  components: {
    EyeIcon,
    EyeOffIcon,
  },
  mixins: [FeaturesMixin, UwFeaturesMixin],
  methods: {
    validateInputs() {
      // Clear error text
      this.emailError = "";
      this.passwordError = "";

      // This can be more complex... Just checking non-empty here
      if (this.model.email.length === 0) {
        this.emailError = "Email address is required to log in";
        return false;
      }
      if (this.model.password.length === 0) {
        this.passwordError = "Your password is required to log in";
        return false;
      }

      return true;
    },
    incrementAttemptsForEmail() {
      if (!this.loginAttemptsPerEmail[this.model.email]) {
        this.$set(this.loginAttemptsPerEmail, this.model.email, 0);
      }
      this.loginAttemptsPerEmail[this.model.email]++;
    },
    async login() {
      this.loggingIn = true;
      if (!this.validateInputs()) {
        this.loggingIn = false;
        return;
      }
      const success = await this.$store.dispatch("login", this.model);
      if (success) {
        this.$emit('afterlogin');
      } else {
        this.incrementAttemptsForEmail();

        if (this.loginAttempts >= this.passwordLockoutLimit) {
          this.passwordError =
              "If an account exists with these details, a password reset email has been sent";
        } else if (this.loginAttempts === this.passwordLockoutLimit - 1) {
          this.passwordError =
              "Unable to log in, you have one attempt remaining, after which a password reset email will be sent";
          this.$refs.password.focus();
        } else {
          this.passwordError =
              "Unable to log in, please check that your details are correct and try again";
          this.$refs.password.focus();
        }
      }
      this.loggingIn = false;
    },
    ssoLogin() {
      this.$store.commit('setLoggingInWithSSO', { loggingIn: true });
      this.handleSSOLoginStateReset();

      let ssoUrl = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/simplesamlLogin";
      if (this.ssoIdP !== "") {
        // URL encode the IdP value
        ssoUrl += "/" + encodeURIComponent(this.ssoIdP);
        ssoUrl += "/" + encodeURIComponent(USER_TYPE.UNDERWRITER);
      } else {
        ssoUrl += "/null/" + encodeURIComponent(USER_TYPE.UNDERWRITER);
      }

      window.location.href = ssoUrl;
    },
    async handleSSOLogin() {
      if (this.$route.query.sso == 1) {
        this.$devLog("%cFinding user for SSO", "color: red; font-size: 1.5em;");
        const success = await AuthService.loginSSO();
        if (!success) {
          this.$devLog("SSO login failed");
          return;
        }
        // this.$store.commit('setLocallyAuthed', { authed: true });
        this.$store.commit('setLoggingInWithSSO', { loggingIn: false });

        this.$router.push({name: 'Inquiries'});
      } else if (this.$route.query.sso == 0) {
        this.$toasted.error(
          "You do not have permission to access this tool at the moment - please contact your IT administrator to request"
        );
        // remove the sso query from the url
        this.$router.replace({ query: {} });
      } else {
        // use the value of the sso query in the ssoLogin url
        this.$devLog("%cSetting SSO service provider", "color: red; font-size: 1.5em;");
        this.ssoIdP = this.$route.query.sso;
      }
    },
    handleSSOLoginStateReset() {
      const RESET_SSO_LOGIN_TIMEOUT_SECONDS = 8;
      if (this.$store.getters.loggingInWithSSO) {
        setTimeout(() => {
          if (this.$store.getters.loggingInWithSSO) {
            this.$store.commit('setLoggingInWithSSO', { loggingIn: false });
            this.$devLog("SSO login didn't happen after", RESET_SSO_LOGIN_TIMEOUT_SECONDS, "seconds - resetting SSO login state");
          }
        }, RESET_SSO_LOGIN_TIMEOUT_SECONDS * 1000);
      }
    }
  },
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      inputType: this.getLoginFormInputType(),
      autocomplete: this.getLoginFormAutocomplete(),
      loggingIn: false,
      emailError: "",
      passwordError: "",
      passwordVisible: false,
      loginAttemptsPerEmail: {},
      ssoIdP: "",
    };
  },
  computed: {
    passwordLockoutLimit() {
      return process.env.VUE_APP_PASSWORD_LOCKOUT_LIMIT;
    },
    loginAttempts() {
      return this.loginAttemptsPerEmail[this.model.email] ?? 0;
    },
  },
  async mounted() {
    this.handleSSOLoginStateReset();

    // Reworked from the Inquiry Tool
    // Magic login link contains ?token=XYZ... fire login process if present
    if (!this.$store.getters.isLoggedIn) {
      if (this.usingSSOAuth() && Object.prototype.hasOwnProperty.call(this.$route.query, "sso")) {
        await this.handleSSOLogin();
      } else if (Object.prototype.hasOwnProperty.call(this.$route.query, "token")) {
        const success = await this.$store.dispatch("loginWithToken", {
          token: this.$route.query.token,
        });

        if (!success) {
          this.$toasted.info("Sorry, that login link has expired");
          return;
        }
        this.afterLogin();
      }
    }
  },
};
</script>
